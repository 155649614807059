.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #3e497a;
}

.about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #2a1161;
    color: #f0f0f0;
}

.about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    color: gold;
    height: 50px;
}

.about img {
    display: flex;
    height: 140px;
    border-radius: 100px;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 40px;
    margin-top: 40px;
    animation: rotate 6s linear infinite reverse;
}

/*.about img:hover {*/
/*    animation: rotate 6s linear infinite reverse;*/
/*}*/

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.about .prompt {
    width: 40%;
    font-size: 30px;
}

.prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
}

.skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #2a1161;
}

.icons-area {
    font-size: 35px;
    color: #2a1161;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 30px;
    justify-content: center;
    height: 60%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.icons-area img {
    display: block;
    margin: auto;
    height: 100px;
    width: 100px;
    border-radius: 10px
}

.icons-area div:hover {
    cursor: pointer;
    transform: scale(1.1) rotate(-3deg);
}

.skills .list {
    list-style: none;
    width: 60%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.list span {
    font-size: 20px;
}

.icons {
    cursor: pointer;
}

.icons:hover {
    color: gold;
    transform: scale(1.1);
}

.label-icons {
    font-size: 20px;
    font-weight: bold;
}

/*.label-icons:hover {*/
/*    font-size: 20px;*/
/*    transform: scale(1.1) rotate(-3deg);*/
/*}*/

@media only screen and (max-width: 1000px) {

    .prompt .description {
        font-size: 25px;
    }

    .skills {
        font-size: 25px;
    }

    .icons {
        margin-bottom: 100px;
    }

    .skills {
        margin-bottom: 10px;
        margin-top: 4rem;
    }
}

@media only screen and (max-width: 950px) {
    .about h2 {
        margin-bottom: 150px;
        font-size: 70px;
    }
}


@media (min-width: 768px) {
    .icons-area {
        margin-top: 50px;
        margin-bottom: 50px;
        grid-template-columns: repeat(6, 1fr);
        gap: 50px;
    }
}

@media only screen and (max-width: 700px) {
    .about h2 {
        font-size: 50px;
        margin-bottom: 70px;
    }

    .skills {
        margin-top: 7rem;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .about h2 {
        font-size: 40px;
        margin-bottom: 70px;
    }

    .about .prompt {
        margin-top: 10px;
        font-size: 20px;
        width: 80%;
    }

    .skills {
        margin-top: 4rem;
        text-align: center;
    }

    .list {
        padding: 0;
    }

    .skills h2 {
        font-size: 30px;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
